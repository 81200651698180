import React, { Component } from "react";
import sectionImage from "../images/section-icon.png";
import videoImage from "../images/jci-foundation-logo.png";
// import ModalVideo from "react-modal-video";
// import "react-modal-video/css/modal-video.min.css";

class EntryArea extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  handleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <React.Fragment>
        <section className="entry-area">
          <div className="container">
            <div className="row entry-static-wrap">
              <div className="col-lg-3">
                <div className="entry-static-box entry-static-box1">
                  <div className="section-icon">
                    <img src={sectionImage} alt="section-icon" />
                  </div>
                  <h4 className="entry__title">No one has ever become poor by giving</h4>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="entry-static-box entry-static-box2 d-flex align-items-center">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="entry-video-img">
                        <img src={videoImage} alt="entry-video-img" />
                        {/* <div
                          onClick={this.handleModal}
                          onKeyUp={this.handleModal}
                          role="button"
                          tabIndex="0"
                          className="mfp-iframe video-play-btn"
                          title="Play Video"
                        >
                          <i className="fa fa-play"></i>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="entry-video-text">
                        <h4 className="entry__title">
                          "Our fingerprints on the lives we touch never fade"
                        </h4>
                        <p className="entry__text">
                          {/* Watch us how we take care of everyone */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="wmk7kwE4sUM"
          onClose={this.handleModal}
        /> */}
      </React.Fragment>
    );
  }
}

export default EntryArea;
