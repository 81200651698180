import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import HomeSlider from "../components/sliders/HomeSlider";
import PromoVideo from "../components/PromoVideo";
import HomeServiceArea from "../components/services/HomeServiceArea";
import HomeServiceArea2 from "../components/services/HomeServiceArea2";
import HomeCounter1 from "../components/counter/HomeCounter1";
import HomeClientsLogo from "../components/HomeClientsLogo";
import CallToAction from "../components/CallToAction";
import Map from "../components/Map";
import HomeCallToActionOne from "../components/HomeCallToActionOne";
// import CausesArea from "../components/CausesArea";
// import HelpingArea from "../components/HelpingArea";
// import TeamArea from "../components/TeamArea";
// import BlogHome from "../components/BlogHome";

const HomePage = () => {
  return (
    <Layout pageTitle="JCI India Foundation | Welcome to all">
      <NavOne />
      <HomeSlider />
      <PromoVideo />
      <HomeServiceArea />
      <HomeCounter1 />
      <HomeServiceArea2 />
      <CallToAction />
      <HomeClientsLogo />
      <Map />
      <HomeCallToActionOne />
      {/* <CausesArea />
      <div className="section-divider"></div>
      <HelpingArea />
      <TeamArea />
      <BlogHome /> */}
      <Footer />
    </Layout>
  );
};

export default HomePage;
