import React, { Component } from "react";
import { Link } from "gatsby";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import sectionIcon from "../../images/section-icon.png";

class HomeCounter1 extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <div>
        <section className="mixer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-heading mixer-heading">
                  <div className="section-icon">
                    <img src={sectionIcon} alt="section-icon" />
                  </div>
                  <h2 className="section__title text__white">
                    Contribute for the people and causes you care about
                  </h2>
                  <Link to="/donate" className="theme-btn">
                    donate now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mixer-area2">
          <div className="container">
            <div className="row fun-content-wrap">
              <div className="col-lg-6">
                <div className="fun-content">
                  <div className="fun-item fun-item1">
                    <i className="fa fa-book"></i>
                    <h3 className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 100000 : 0} />
                      </VisibilitySensor>
                    </h3>
                    <p className="fun__text">
                      students are supported every year
                    </p>
                  </div>
                </div>
                <div className="fun-content">
                  <div className="fun-item fun-item2">
                    <i className="fa fa-book"></i>
                    <h3 className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 500000 : 0} />
                      </VisibilitySensor>
                    </h3>
                    <p className="fun__text">
                      youngsters are trained every year
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="fun-content-slide">
                  <Swiper>
                    {/* <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        Every man must decide whether he will walk in the light
                        of creative altruism or in the darkness of destructive
                        selfishness.
                      </h3>
                      <p className="funslide__name">Martin Luther King, jr</p>
                    </div> */}
                    <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        Your contributions to JCI India Foundation are exempted under 80G & 12A of the income tax act of Government of India.
                      </h3>
                      {/* <p className="funslide__name">Myles Munroe</p> */}
                    </div>
                    <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        Donations to the Foundation remain in an interest-bearing account and the interest generated on such contribution is allotted for JCI India programs.
                      </h3>
                      {/* <p className="funslide__name">MH. Jackson Brown Jr.</p> */}
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeCounter1;
