import React from "react";
import { Link } from "gatsby";
import hlwImage1 from "../../images/school.png";
import hlwImage2 from "../../images/how-to.png";
import hlwImage3 from "../../images/appreciate.png";

const HomeServiceArea = () => {
  return (
    <section className="hiw-area">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="hiw-item">
              <div className="hiw-item-img-box hiw-bg2">
                <figure>
                  <img src={hlwImage2} alt="" />
                  <h3 className="hiw-title">How to donate</h3>
                  <div className="hiw-btn-box">
                    <Link to="/how-to-donate" className="theme-btn">
                      read more
                    </Link>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="hiw-item">
              <div className="hiw-item-img-box hiw-bg1">
                <figure>
                  <img src={hlwImage3} alt="" />
                  <h3 className="hiw-title">Recognition for donation</h3>
                  <div className="hiw-btn-box">
                    <Link to="/recognition-for-donation" className="theme-btn">
                      read more
                    </Link>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="hiw-item">
              <div className="hiw-item-img-box hiw-bg3">
                <figure>
                  <img src={hlwImage1} alt="" />
                  <h3 className="hiw-title">Your donation makes a diiference</h3>
                  <div className="hiw-btn-box">
                    <Link to="/donation-makes-diiference" className="theme-btn">
                      read more
                    </Link>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServiceArea;
