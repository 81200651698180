import React from 'react';
import Carousel from 'nuka-carousel';
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
  <div style={{ width: "100%", height: "100%" }}>
    <Row>
      <Col xl={12} lg={12} >
        <ContentLoader
          speed={2}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="-2" y="-47" rx="0" ry="0" width="595" height="271" />
        </ContentLoader>
      </Col>
    </Row>
  </div >
)

var Api_url = process.env.GATSBY_API_URL;
console.log({ Api_url });

const axiosConfig = {
  headers: {
    "Content-Type": "application/json; text/html; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
};

class HomeSlider extends React.Component {
  constructor() {
    super();
    this.state = {
      Slides: [],
      LoadingFlag: false,
      TotalSlides: 0,
    }
  };

  handleChange(e) {
    // this.setState({ LomId: event.target.value });
    this.setState({ [e.target.name]: e.target.value });
  }

  getAllRecords = async () => {
    console.log("getAllRecords called ");
    var url = Api_url + `/api/app-settings-content`;
    console.log("url=" + url);
    var postData = {
      UserInterface: 1,
      AppName: "foundation_website",
      SettingsName: "home_slideshow",
    };
    this.setState({ LoadingFlag: true });
    console.log({ postData });
    // this.setState({ isLoading: true });
    await axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));

        var records = res.data.records;
        console.log({ records });
        var result = records.SettingsValues;
        var n1 = result.length;
        console.log("n1=" + n1);

        this.setState({ LoadingFlag: false, Slides: result, TotalSlides: n1 });
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount() {
    console.log("componentDidMount called ");
    // window.location.replace('https://www.google.com')
    this.getAllRecords();
  }

  render() {
    return (
      <div>
        {this.state.LoadingFlag ? <MyLoader /> :
          <Carousel autoplay="true" slidesToScroll="auto" wrapAround="true" renderBottomCenterControls={false}>
            {this.state.Slides.map((item, index) => (
              <div>
              <img style={{width:'100%'}} src={item.FilePath}/>
              {item.SettingsValuesDescription ? <p style={{marginTop: '-70px', textAlign: 'center', color:'white', textShadow: '2px 2px 2px black, 2px -2px 2px black, -2px 2px 2px black, -2px -2px 2px black, 2px 0px 2px black, 0px 2px 2px black, -2px 0px 2px black, 0px -2px 2px black', fontFamily: 'Helvetica, sans-serif', fontWeight: 200, fontSize: '24px',position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)'}}> {item.SettingsValuesDescription} </p> : ''}
              </div>
            ))}
          </Carousel>
        }

      </div>
    );
  }
}
export default HomeSlider;
