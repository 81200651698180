import React from "react";
import { Link } from "gatsby";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import clientLogoImage1 from "../images/hgf.png";
import clientLogoImage2 from "../images/jfm.png";
import clientLogoImage3 from "../images/jfd.png";
import clientLogoImage4 from "../images/jfp.png";
import clientLogoImage5 from "../images/jff.png";
import clientLogoImage6 from "../images/jfs.png";
import clientLogoImage7 from "../images/rpp.png";
import clientLogoImage8 from "../images/jfj.png";
import clientLogoImage9 from "../images/jfa.png";
import clientLogoImage10 from "../images/jfg.png";
import clientLogoImage11 from "../images/jfk.png";
import clientLogoImage12 from "../images/jfr.png";

const ClientLogos = [
  {
    'TitleName': 'HGF',
    'TitleLink': '/titles/hgf-title',
    'TitleImagePath': clientLogoImage1
  },
  {
    'TitleName': 'JFM',
    'TitleLink': '/titles/jfm-title',
    'TitleImagePath': clientLogoImage2
  },
  {
    'TitleName': 'JFD',
    'TitleLink': '/titles/jfd-title',
    'TitleImagePath': clientLogoImage3
  },
  {
    'TitleName': 'JFA',
    'TitleLink': '/titles/jfa-title',
    'TitleImagePath': clientLogoImage9
  },
  {
    'TitleName': 'JFP',
    'TitleLink': '/titles/jfp-title',
    'TitleImagePath': clientLogoImage4
  },
  {
    'TitleName': 'JFF',
    'TitleLink': '/titles/jff-title',
    'TitleImagePath': clientLogoImage5
  },
  {
    'TitleName': 'JFS',
    'TitleLink': '/titles/jfs-title',
    'TitleImagePath': clientLogoImage6
  },
  {
    'TitleName': 'JFG',
    'TitleLink': '/titles/jfg-title',
    'TitleImagePath': clientLogoImage10
  },
  {
    'TitleName': 'RPP',
    'TitleLink': '/titles/rpp-title',
    'TitleImagePath': clientLogoImage7
  },
  {
    'TitleName': 'JFR',
    'TitleLink': '/titles/jfr-title',
    'TitleImagePath': clientLogoImage12
  },
  {
    'TitleName': 'JFJ',
    'TitleLink': '/titles/jfj-title',
    'TitleImagePath': clientLogoImage8
  },
  {
    'TitleName': 'JFK',
    'TitleLink': '/titles/jfk-title',
    'TitleImagePath': clientLogoImage11
  }
];

const HomeClientsLogo = () => {
  const params = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <section className="clientlogo-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="client-logo">
              <Swiper {...params}>
                {
                  ClientLogos.map(item => (
                    <div className="client-logo-item">
                      <Link to={item.TitleLink}> <img src={item.TitleImagePath} alt={item.TitleName} width="200px" /> </Link>
                    </div>
                  ))
                }
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeClientsLogo;
