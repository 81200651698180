import React from "react";
import { Link } from "gatsby";

const HomeCallToActionOne = () => {
  return (
    <section className="cta-area text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="cta-item cta-item1">
              <h3 className="cta__title">Sponsor for better tomorrow</h3>
              <Link to="/donate" className="theme-btn">
                donate now
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="cta-item cta-item2">
              <h3 className="cta__title">Take a look at our recognitions</h3>
              <Link to="/recognition-for-donation" className="theme-btn">
                view all
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCallToActionOne;
